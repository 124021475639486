import TitleDecor from '../TitleDecor/TitleDecor';
import { NewsArticles } from '@local/components';
import {
  Image,
  Heading,
  Text,
  Link,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import s from './EventsSection.module.css';
import { DateFormat } from '@nintendo-of-america/localizer';

function OneEvent({ article, inStoreEventLocation }) {
  const { date } = useLocalizer();
  const { id, title, media, publishDate, body, slug } = article;
  return (
    <div className={s.oneEvent} key={id}>
      <div>
        <Image assetPath={media.publicId} className={s.image} alt="" />
      </div>
      <div className={s.text}>
        <p>{date(publishDate, { format: DateFormat.SHORT })}</p>
        <Heading>{title}</Heading>
        <Text>{body.snippet}</Text>
        <Link href={`/retail-locations/${inStoreEventLocation}/${slug}`}>
          Read more
        </Link>
      </div>
    </div>
  );
}

function EventsSection({
  articles,
  decorLeft,
  decorRight,
  title,
  inStoreEventLocation,
}) {
  return (
    <div className={s.container}>
      <TitleDecor decorLeft={decorLeft} decorRight={decorRight} title={title} />
      {articles.length === 1 ? (
        <OneEvent
          article={articles?.[0]}
          inStoreEventLocation={inStoreEventLocation}
        />
      ) : (
        <NewsArticles
          articles={articles.slice(0, 7)}
          inStoreEventLocation={inStoreEventLocation}
        />
      )}
    </div>
  );
}

export default EventsSection;
