import React, { useLayoutEffect, useState } from 'react';
import {
  ResponsivePicture,
  ChevronLeftIcon,
} from '@nintendo-of-america/component-library';
import { RichText, Section } from '@local/components';
import BranchDetailSection from '../BranchDetailSection';
import MarqueeGallery from '../MarqueeGallery';
import SocialMediaSection from '../../RetailLocations/SocialMediaSection';
import ProductsSection from '../ProductsSection/ProductsSection';
import EventsSection from '../EventsSection/EventsSection';
import MNSSection from '../../RetailLocations/MNSSection';
import NavFarm from '../../RetailLocations/NavFarm';
import s from './RetailBranchPage.module.css';
import FAQSection from '../../RetailLocations/FAQSection';
import RetailStoreBanner from '../../RetailLocations/RetailStoreBanner';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import RetailLocationsButton from '../../RetailLocations/RetailLocationsButton';

function RetailBranchPage({
  storeName,
  heroImage,
  banner,
  navItems,
  address,
  phone,
  email,
  storeHours,
  mapUrl,
  externalMapLink,
  message,
  storeImages,
  socialMediaText,
  productSectionTitle,
  productsSectionBackground,
  carouselSlides,
  inStoreEventsTitle,
  inStoreEventsTitleDecorLeft,
  inStoreEventsTitleDecorRight,
  articles,
  myNintendoStoreText,
  myNintendoStoreCta,
  myNintendoStoreSectionBackground,
  lightText,
  faqHeading,
  faqQuestions,
  faqSeeAllLink,
  legalDisclaimer,
  location,
}) {
  const [pageLoaded, setPageLoaded] = useState(false);
  const { text } = useLocalizer();

  useLayoutEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const handleLoad = () => {
      setPageLoaded(true);
      scrollToHash();
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    const handleHashChange = () => {
      if (pageLoaded) {
        scrollToHash();
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      window.removeEventListener('load', handleLoad);
    };
  }, [pageLoaded]);
  return (
    <div>
      {/* Hero Section */}
      <ResponsivePicture
        primary={heroImage?.primary?.assetPath}
        secondary={heroImage?.secondary?.assetPath}
        alt={heroImage?.alt}
        fullWidth
      />
      {/* Notification Banner */}
      {banner?.body && (
        <RetailStoreBanner
          header={banner.heading}
          text={banner.body}
          cta={banner.cta}
        />
      )}
      {/* Nav Section */}
      <NavFarm
        items={navItems}
        productsLength={carouselSlides?.length}
        eventsLength={articles?.length}
      />
      {/* Location Section */}
      <div className={s.section}>
        <Section id="location" constrained>
          <BranchDetailSection
            name={storeName}
            address={address}
            phone={phone}
            email={email}
            storeHours={storeHours}
            message={message}
            mapUrl={mapUrl}
            externalMapLink={externalMapLink}
            location={location}
          />
        </Section>
        {storeImages.length > 0 && <MarqueeGallery storeImages={storeImages} />}
      </div>
      {/* Social Media Section*/}
      <SocialMediaSection text={socialMediaText} />
      {/* Products Section */}
      {carouselSlides?.length ? (
        <Section
          style={{
            backgroundImage: `url(${productsSectionBackground})`,
            backgroundSize: '2%',
          }}
          id="products"
          constrained
        >
          <ProductsSection
            lightText={lightText}
            slides={carouselSlides}
            title={productSectionTitle}
          />
        </Section>
      ) : null}
      {/* In-Store Events Section */}
      {articles?.length > 0 && (
        <Section id="events" constrained>
          <EventsSection
            decorLeft={inStoreEventsTitleDecorLeft}
            decorRight={inStoreEventsTitleDecorRight}
            title={inStoreEventsTitle}
            articles={articles}
            inStoreEventLocation={location}
          />
        </Section>
      )}
      {/* MyNintendo Store Section */}
      <Section
        style={{ backgroundImage: `url(${myNintendoStoreSectionBackground})` }}
        constrained
        className={s.mnsSection}
      >
        <MNSSection
          text={myNintendoStoreText}
          cta={myNintendoStoreCta}
          link={'/store'}
        />
      </Section>
      {/* FAQ Section */}
      <Section constrained id="faq" className={s.section}>
        <FAQSection
          title={faqHeading}
          faqs={faqQuestions}
          seeAllLink={faqSeeAllLink}
        />
      </Section>
      {/* Other Locations Section */}
      <Section constrained className={s.otherLocations}>
        <h2>{text('Check out other locations')}</h2>
        <RetailLocationsButton url="/retail-locations" icon={ChevronLeftIcon}>
          {text('See other locations')}
        </RetailLocationsButton>
      </Section>
      {/* Legal Disclaimer Section */}
      {legalDisclaimer && (
        <Section constrained>
          <RichText textVariant={'legal'} data={legalDisclaimer} />
        </Section>
      )}
    </div>
  );
}

export default RetailBranchPage;
