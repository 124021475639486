import { Image, Heading } from '@nintendo-of-america/component-library';
import s from './TitleDecor.module.css';

const TitleDecor = ({ title, decorLeft, decorRight }) => {
  return (
    <div className={s.container}>
      <Image assetPath={decorLeft} alt="" />
      <Heading>{title}</Heading>
      <Image assetPath={decorRight} alt="" />
    </div>
  );
};

export default TitleDecor;
