import { Image } from '@nintendo-of-america/component-library';
import s from './MarqueeGallery.module.css';

const MarqueeGallery = ({ storeImages }) => {
  return (
    <div className={s.images}>
      <div className={s.imagesSlide}>
        {storeImages?.map((item, index) => (
          <Image
            key={index}
            assetPath={item?.primary?.assetPath}
            alt={item?.alt}
          />
        ))}
      </div>
      <div className={s.imagesSlide}>
        {storeImages?.map((item, index) => (
          <Image
            key={index}
            assetPath={item?.primary?.assetPath}
            alt={item?.alt}
          />
        ))}
      </div>
    </div>
  );
};

export default MarqueeGallery;
