import { Button } from '@nintendo-of-america/component-library';
import s from './RetailLocationsButton.module.css';
import clsx from 'clsx';

const RetailLocationsButton = ({ url, icon, children, reverse }) => {
  return (
    <Button
      icon={icon}
      href={url}
      className={clsx(s.font, reverse && s.reverse)}
    >
      {children}
    </Button>
  );
};

export default RetailLocationsButton;
