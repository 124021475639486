import {
  Heading,
  ChevronRightIcon,
} from '@nintendo-of-america/component-library';
import { RichText, AccordionRow } from '@local/components';
import s from './FAQSection.module.css';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import clsx from 'clsx';
import RetailLocationsButton from '../RetailLocationsButton';

const FAQSection = ({ title, faqs, seeAllLink, faqPage }) => {
  const { text } = useLocalizer();
  const questions = faqPage ? faqs : faqs.slice(0, 3);

  return (
    <div className={s.container}>
      <Heading
        className={clsx(faqPage ? s.leftAlign : s.centerAlign, s.heading)}
      >
        {faqPage ? title : text('Frequently asked questions (FAQs)')}
      </Heading>
      {questions.map((item) => (
        <div key={item.heading} className={s.accordionText}>
          <AccordionRow heading={item.heading} rounded redVariant>
            <RichText data={item.body} />
          </AccordionRow>
        </div>
      ))}
      {seeAllLink && (
        <RetailLocationsButton
          url={'/retail-locations/faq/'}
          icon={ChevronRightIcon}
          reverse
        >
          {text('See all FAQs')}
        </RetailLocationsButton>
      )}
    </div>
  );
};

export default FAQSection;
