import {
  TwitterIcon,
  InstagramIcon,
} from '@nintendo-of-america/component-library';
import s from './SocialMediaSection.module.css';
import clsx from 'clsx';

const SOCIAL_MEDIA_URL = {
  x: 'https://x.com/NintendoStoreUS',
  instagram: 'https://www.instagram.com/NintendoStoreUS/',
};

const SocialMediaSection = ({ text }) => {
  return (
    <div className={clsx(s.container)}>
      {text}
      <div className={clsx(s.icons)}>
        <a
          href={SOCIAL_MEDIA_URL.instagram}
          aria-label="link to Nintendo Stores US Instagram"
        >
          <InstagramIcon width={32} />
        </a>
        <a href={SOCIAL_MEDIA_URL.x}>
          <TwitterIcon width={32} aria-label="link to Nintendo Stores US X" />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaSection;
