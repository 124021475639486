import StoreMap from '../StoreMap';
import s from './BranchDetailSection.module.css';
import { Link, OffsiteIcon } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { RichText } from '@local/components';

const GOOGLE_MAPS = {
  SF_GOOGLE_MAPS: 'https://maps.app.goo.gl/XSqSqvhneJjd6YAS9',
  NY_GOOGLE_MAPS: 'https://maps.app.goo.gl/unvWNnJEiKWsgmeF9',
};

const googleMapLink = (location) => {
  if (location === 'new-york') {
    return GOOGLE_MAPS.NY_GOOGLE_MAPS;
  }
  if (location === 'san-francisco') {
    return GOOGLE_MAPS.SF_GOOGLE_MAPS;
  }
  return null;
};

const BranchInfo = ({
  name,
  address,
  phone,
  email,
  storeHours,
  message,
  externalMapLink,
}) => {
  const { text } = useLocalizer();

  return (
    <div className={s.textstyle}>
      <h1 className={s.storeName}>{name}</h1>
      <div className={s.storeinfo}>
        <div>
          <h2>{text('Address')}</h2>
          <RichText data={address} />
          <Link href={externalMapLink} className={s.link} target="_blank">
            <OffsiteIcon size={14} /> {text('Google Maps')}
          </Link>
        </div>
        <div>
          <h2>{text('Phone')}</h2>
          <Link href={`tel:${phone}`} locale="false" className={s.link}>
            {phone}
          </Link>
        </div>
        <div>
          <h2>{text('Email')}</h2>
          <Link href={`mailto:${email}`} className={s.link}>
            {email}
          </Link>
        </div>
        <div>
          <h2>{text('Store hours')}</h2>
          <RichText data={storeHours} />
        </div>

        {message?.heading && message?.body && (
          <div>
            <hr className={s.divider} />
            <h2>{message?.heading}</h2>
            <RichText data={message?.body} />
          </div>
        )}
      </div>
    </div>
  );
};

const BranchDetailSection = ({
  name,
  address,
  phone,
  email,
  storeHours,
  message,
  location,
}) => {
  return (
    <div className={s.gridcontainer}>
      <BranchInfo
        name={name}
        address={address}
        phone={phone}
        email={email}
        storeHours={storeHours}
        message={message}
        externalMapLink={googleMapLink(location)}
      />
      <StoreMap location={location} />
    </div>
  );
};

export default BranchDetailSection;
