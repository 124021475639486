import { Heading, Image } from '@nintendo-of-america/component-library';
import { RichText } from '@local/components';
import s from './ProductsSection.module.css';

export const Slide = ({ slide }) => {
  return (
    <div>
      <Image
        className={s.slideImage}
        assetPath={slide?.fields?.asset?.fields?.primary?.[0]?.url}
        alt=""
      />
      <div className={s.slideText}>
        <Heading>{slide?.fields?.heading}</Heading>
        <RichText data={slide?.fields?.body} />
      </div>
    </div>
  );
};
