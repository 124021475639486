import s from './StoreMap.module.css';

const StoreMap = ({ location }) => {
  return (
    <div>
      {location === 'new-york' && (
        <iframe
          className={s.container}
          title="Interactive map of Nintendo NEW YORK"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1807881737504!2d-73.98435041001444!3d40.75804826102825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258fee5ee15dd%3A0x7933b0d7213afb5!2sNintendo%20New%20York!5e0!3m2!1sen!2sus!4v1738915470578!5m2!1sen!2sus"
        ></iframe>
      )}
      {location === 'san-francisco' && (
        <iframe
          className={s.container}
          title="Interactive map of Nintendo SAN FRANCISCO"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d397.56872106064!2d-122.40859291666736!3d37.78743193153285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808ee07be08f%3A0xc4dc62d567aa977!2s331%20Powell%20St%2C%20San%20Francisco%2C%20CA%2094102!5e0!3m2!1sen!2sus!4v1741806613277!5m2!1sen!2sus"
        ></iframe>
      )}
    </div>
  );
};

export default StoreMap;
