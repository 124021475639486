import {
  MyNintendoStoreLogoIcon,
  defaultTheme,
  Button,
} from '@nintendo-of-america/component-library';
import s from './MNSSection.module.css';

const MNSSection = ({ text, cta, link }) => {
  return (
    <div className={s.container}>
      <div>
        <MyNintendoStoreLogoIcon size={256} color={defaultTheme.color.white} />
      </div>
      <div className={s.text}>{text}</div>
      <Button
        className={s.shopNowButton}
        variant="secondary"
        size="small"
        href={link}
      >
        <div className={s.shopNowText}>{cta}</div>
      </Button>
    </div>
  );
};

export default MNSSection;
