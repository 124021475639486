import s from './NavFarm.module.css';
import { Button } from '@nintendo-of-america/component-library';

const showButton = (label, productsLength, eventsLength) => {
  return (
    (label !== 'Products' && label !== 'Events') ||
    (label === 'Products' && productsLength > 0) ||
    (label === 'Events' && eventsLength > 0)
  );
};

const NavFarm = ({ items, productsLength, eventsLength }) => {
  return (
    <div className={s.background}>
      <div className={s.farm}>
        {items?.map(
          ({ label, url }) =>
            showButton(label, productsLength, eventsLength) && (
              <Button href={url} key={label}>
                {label}
              </Button>
            )
        )}
      </div>
    </div>
  );
};

export default NavFarm;
