import { Carousel, Heading } from '@nintendo-of-america/component-library';
import { Slide } from './Slide';
import s from './ProductsSection.module.css';

function ProductsSection({ slides, title, lightText }) {
  return (
    <div className={lightText && s.lightText}>
      {slides?.length > 0 && (
        <div>
          <Heading variant="h2" className={s.title}>
            {title}
          </Heading>
          <Carousel>
            {slides?.map((slide, i) => (
              <Slide key={i} slide={slide} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}

export default ProductsSection;
