import { Link, MegaphoneIcon } from '@nintendo-of-america/component-library';
import { RichText } from '@local/components';
import s from './RetailStoreBanner.module.css';

const RetailStoreBanner = ({ header, text, cta }) => {
  return (
    <>
      <div className={s.notification}>
        <div className={s.bannercontainer}>
          <div className={s.bannerheadingwrapper}>
            <MegaphoneIcon />
            <p>{header}</p>
          </div>
          <RichText data={text} className={s.bannertext} />
          <Link className={s.calltoaction} href={cta?.url} role="link">
            {cta?.label}
          </Link>
        </div>
      </div>
    </>
  );
};

export default RetailStoreBanner;
